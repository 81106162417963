import React from 'react';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList,
  CommandInput,
} from './command';
import { Popover, PopoverContent, PopoverTrigger } from './popover';
import InfiniteScrollContainer from '../InfiniteScrollContainer';
import { cn } from '@/lib/utils';
import { ChevronDown, Loader2 } from 'lucide-react';
import { Button } from './button';

type SelectInputProp = {
  options: { label: React.ReactNode; value: string; searchKey?: string }[];
  placeholder?: string;
  searchPlaceholder?: string;
  disabled?: boolean;
  name?: string;
  onChange?: (val: { target: { name?: string; value?: string } }) => void;
  value?: string;
  opened?: boolean;
  onOpenChange?: (open: boolean) => void;
  errorMessage?: string;
  searchTerm?: string;
  onSearchChange?: (v: string) => void;
  loading?: boolean;
  component?: React.ReactNode;
  onBottomReached?: () => void;
};

export function SelectInput({
  placeholder,
  disabled,
  name,
  onChange,
  value,
  options,
  searchPlaceholder,
  errorMessage,
  opened,
  onOpenChange,
  loading,
  component,
  onBottomReached = () => null,
}: Readonly<SelectInputProp>) {
  const [open, setOpen] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState('');

  const data = React.useMemo(
    () => options.filter((v) => v.searchKey?.toLowerCase().includes(searchTerm.toLowerCase())),
    [options, searchTerm]
  );

  const handleSearch = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  }, []);

  const checkSearchable = React.useCallback(() => {
    if (loading) return false;
    if (options.length < 5) return false;
    const hasSearchKey = Object.keys(options[0]).includes('searchKey');
    if (options.length > 5 && hasSearchKey) return true;
    if (options.length > 5 && !hasSearchKey)
      throw new Error('searchKey is required in the options since your options is bigger than 5');
  }, [options, loading]);

  return (
    <>
      <Popover
        open={Boolean(open || opened)}
        onOpenChange={(v) => {
          onOpenChange?.(v);
          setOpen(v);
          setSearchTerm('');
        }}
        modal
      >
        <PopoverTrigger asChild>
          <Button
            disabled={disabled}
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className={cn(
              'h-9 w-full justify-between  px-3 font-normal focus-within:border-purple-brand hover:bg-white  flex duration-500 ease-in-out rounded-sm border border-gray-brand2 bg-white py-1 text-sm transition text-black-brand placeholder:text-gray-brand9 focus-visible:outline-none focus-visible:ring-0 focus-visible:border-purple-brand disabled disabled:bg-gray-brand4',
              errorMessage && 'focus-visible:ring-opal-brand5 border-opal-brand5'
            )}
          >
            {value ? (
              options.find((option) => option.value === value)?.label
            ) : (
              <span className="text-neutral-400">{placeholder}</span>
            )}
            <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent
          className="popover-content-width-same-as-its-trigger w-full p-0 z-[1111]"
          align="start"
        >
          <Command className="px-0">
            {checkSearchable() && (
              <CommandInput
                placeholder={searchPlaceholder}
                onValueChange={(v) => setSearchTerm(v)}
                className="h-9 w-full"
                value={searchTerm}
              />
            )}
            <CommandList className="px-0">
              <div className="h-36 w-full">
                <InfiniteScrollContainer className="h-full" onButtonReached={onBottomReached}>
                  {!loading && data.length === 0 ? (
                    <CommandEmpty>No record found.</CommandEmpty>
                  ) : (
                    <CommandGroup className="px-0">
                      {loading ? (
                        <div className="flex h-24 w-full items-center justify-center">
                          <Loader2 className="animate-spin text-purple-brand" />
                        </div>
                      ) : (
                        <>
                          {data.map((option) => (
                            <CommandItem
                              key={option.value}
                              value={option.value}
                              style={{ cursor: 'pointer' }}
                              onSelect={(currentValue: string | undefined) => {
                                setOpen(false);
                                onChange?.({
                                  target: {
                                    name,
                                    value: currentValue === value ? '' : currentValue,
                                  },
                                });
                              }}
                              className="px-5"
                            >
                              {option.label}
                            </CommandItem>
                          ))}
                          {component}
                        </>
                      )}
                    </CommandGroup>
                  )}
                </InfiniteScrollContainer>
              </div>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </>
  );
}
