import { getCommunityById } from '@/services/communities.service';
import { useQuery } from '@tanstack/react-query';

const useGetCommunityById = ({ id }: { id: string }) => {
  return useQuery({
    queryKey: ['GetCommunityById', { id }],
    queryFn: () => getCommunityById({ id }),
    enabled: Boolean(id),
  });
};

export default useGetCommunityById;
