import React, { ChangeEvent } from 'react';

import { Input } from './input';
import { SearchIcon } from '@/components/icons';
import { X } from 'lucide-react';
import { cn } from '@/lib/utils';
import { useDebounce } from '@/hooks/use-debounce';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onClose?: () => void;
  showCancel?: boolean;
  handleChange?: (v: string) => void;
}

const SearchInput = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, onClose, showCancel, onChange, handleChange, ...props }, ref) => {
    const [text, setText] = React.useState('');
    const debounceVal = useDebounce(text);

    const inputChange = React.useCallback((e: ChangeEvent<HTMLInputElement>) => {
      setText(e.target.value);
    }, []);

    React.useEffect(() => {
      handleChange?.(debounceVal);
    }, [debounceVal, handleChange]);

    return (
      <div className="space-y-2">
        <div className="relative">
          <button
            type="button"
            className="absolute inset-y-0 start-0 z-10 flex items-center ms-2.5 hover:opacity-80  rounded-full  justify-center"
          >
            <SearchIcon />
          </button>
          <Input
            type="search"
            ref={ref}
            hideErrorIcon
            className={cn('ps-10', showCancel && 'pe-10')}
            onChange={inputChange}
            {...props}
          />
          {showCancel && (
            <button
              className="absolute h-3 w-3 inset-y-0 top-3 end-0 me-2.5 bg-gray-brand2 rounded-full flex items-center justify-center cursor-pointer"
              onClick={onClose}
            >
              <X size={10} className="text-purple-brand" />
            </button>
          )}
        </div>
      </div>
    );
  }
);
SearchInput.displayName = 'SearchInput';

export { SearchInput };
