import { MAX_IMAGE_IN_BYTE, MAX_IMAGE_IN_MEGABYTE } from '@/configs/global-variables';
import React from 'react';
import { useToast } from './use-toast';

const useMediaCapture = () => {
  const { toast } = useToast();
  const imgRef = React.useRef<HTMLInputElement | null>(null);
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const [capturedImage, setCapturedImage] = React.useState<string>('');
  const [imageFile, setImageFile] = React.useState<File | null>(null);
  const [isCapturing, setIsCapturing] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  const startCamera = React.useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (err) {
      console.error('Error accessing the camera', err);
    }
  }, []);

  const stopCamera = React.useCallback(() => {
    if (videoRef.current && videoRef.current.srcObject) {
      const tracks = (videoRef.current.srcObject as MediaStream).getTracks();
      tracks.forEach((track) => track.stop());
    }
  }, []);

  const captureImage = React.useCallback(() => {
    if (videoRef.current) {
      const canvas = document.createElement('canvas');
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      canvas.getContext('2d')?.drawImage(videoRef.current, 0, 0);
      const imageDataUrl = canvas.toDataURL('image/jpeg');
      setCapturedImage(imageDataUrl);
      stopCamera();
    }
  }, []);

  const handleFileInput = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.size <= MAX_IMAGE_IN_BYTE) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onload = (e) => setCapturedImage(e.target?.result as string);
      reader.readAsDataURL(file);
    } else {
      toast({
        variant: 'destructive',
        description: `Image size exceeds the maximum allowed size of ${MAX_IMAGE_IN_MEGABYTE}MB`,
      });
    }
  }, []);

  React.useEffect(() => {
    if (isCapturing && videoRef.current) {
      videoRef.current.play().catch((err) => {
        console.error('Error playing video:', err);
        setError('Failed to start video stream. Please try again.');
        setIsCapturing(false);
      });
    }
  }, [isCapturing]);

  return {
    capturedImage,
    imgRef,
    videoRef,
    handleFileInput,
    captureImage,
    startCamera,
    error,
    canvasRef,
    isCapturing,
    imageFile,
    setCapturedImage,
  };
};

export default useMediaCapture;
