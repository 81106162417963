import React from 'react';

const AvatarPlaceholder = () => {
  return (
    <div className="h-10 w-10 cursor-pointer hover:opacity-65 border-dashed-long rounded-full border-spacing-96 text-gray-brand9 text-base border-gray-brand3 flex items-center justify-center">
      {' '}
      +{' '}
    </div>
  );
};

export default AvatarPlaceholder;
