import { create } from 'zustand';

type CommunitySuccessType = {
  base64: string;
  name: string;
  id: number;
  slug: string;
};

type CommunityStoreType = {
  communitySuccess: CommunitySuccessType | null;
  setCommunitySuccess: (val: CommunitySuccessType | null) => void;
};

const useCommunityStore = create<CommunityStoreType>((set) => ({
  communitySuccess: null,
  setCommunitySuccess: (payload) =>
    set(() => ({
      communitySuccess: payload,
    })),
}));

export default useCommunityStore;
