'use client';
import React from 'react';
import { MotionLazy } from './animate/motion-lazy';
import { AppProgressBar as ProgressBar } from 'next-nprogress-bar';
import { SessionProvider } from 'next-auth/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { TooltipProvider } from './atom/tooltip';
import { MixpanelProvider } from '@/providers/MixPanelProvider';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 1,
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000), // Exponential backoff
    },
  },
});

const Providers = ({ children }: { children: React.ReactNode }) => {
  return (
    <SessionProvider>
      <QueryClientProvider client={queryClient}>
        <MixpanelProvider>
          <MotionLazy>
            <ProgressBar
              height="4px"
              color="#7635dc"
              options={{ showSpinner: false }}
              shallowRouting
            />
            <TooltipProvider>{children}</TooltipProvider>
          </MotionLazy>
        </MixpanelProvider>
      </QueryClientProvider>
    </SessionProvider>
  );
};

export default Providers;
