'use client';

import React from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/atom/dialog';
import { usePathname, useRouter } from 'next/navigation';
import { Button } from '@/components/atom/button';
import useCommunityStore from '@/hooks/store/use-community-store';

export default function CreateCommunitySuccessModal() {
  const router = useRouter();
  const pathname = usePathname();
  const { communitySuccess, setCommunitySuccess } = useCommunityStore();
  return (
    <Dialog open={!!communitySuccess} onOpenChange={() => setCommunitySuccess(null)}>
      <DialogContent className="w-full p-0 antialiased flex flex-col items-center justify-between max-w-[446px] rounded-xl">
        <DialogHeader className="w-full text-start h-32">
          <DialogTitle className="relative h-full w-full flex items-center">
            <span className="sr-only">Community created successfully</span>
            <img
              src={communitySuccess?.base64 ?? ''}
              alt="community-img"
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-purple-brand3/40"></div>

            <div className="absolute -bottom-[50%] left-[35%] border-white border-[4px]  h-32 w-32 min-w-32 rounded-lg">
              <img
                src={communitySuccess?.base64 ?? ''}
                alt="community-img"
                className="w-full h-full object-cover inset-0 rounded-lg"
              />
            </div>
          </DialogTitle>
        </DialogHeader>
        <div className="w-full h-full min-h-[200px] mt-8 overflow-y-auto p-4 md:p-10 flex flex-col items-center justify-center">
          <h1 className="text-2xl font-medium text-purple-brand3 text-center">Community Created</h1>
          <p className="text-sm text-gray-brand7 mb-8 text-center">
            You have successfully created{' '}
            <span className="font-medium">{communitySuccess?.name}</span> community
          </p>
          <Button
            className="w-full mb-3"
            onClick={() => {
              router.push(`/community/${communitySuccess?.slug}`);
              setCommunitySuccess(null);
            }}
          >
            Invite Others To Join Your Community
          </Button>
          <Button
            variant="outline"
            className="w-full text-purple-brand"
            onClick={() => {
              router.push(`${pathname}?type=post&communityId=${communitySuccess?.id}`);
              setCommunitySuccess(null);
            }}
          >
            Create Your First Post
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
