import { useDebounce } from '@/hooks/use-debounce';
import { fetchUsers } from '@/server/actions/users.actions';
import { useInfiniteQuery } from '@tanstack/react-query';
import React from 'react';

export const useGetUsers = ({ enabled = false }) => {
  const [searchText, setSearchText] = React.useState('');
  const search = useDebounce(searchText);
  const { data, status, fetchNextPage, isFetchingNextPage, isFetching, hasNextPage } =
    useInfiniteQuery({
      initialPageParam: 1,
      queryKey: ['GetUsers', search],
      queryFn: ({ pageParam }) => fetchUsers({ page: pageParam, search }),
      getNextPageParam: (lastPage, pages) =>
        lastPage?.data?.data?.length ? pages.length + 1 : undefined,
      enabled,
    });

  const payload =
    data?.pages.reduce((acc, page) => {
      return (page?.data?.data ? [...acc, ...page?.data?.data] : [...acc]).filter(
        (v) => !v.optionalFee
      );
    }, []) ?? [];

  return {
    users: payload,
    isFetchingNextPage,
    loadingUsers: status === 'pending',
    setSearchText,
    hasNextPage,
    isFetching,
    fetchNextPage,
  };
};
