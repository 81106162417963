import { Button } from '@/components/atom/button';
import React from 'react';

const ActionButton = ({
  children,
  icon,
  onClick,
}: {
  children: React.ReactNode;
  icon: React.ReactNode;
  onClick: () => void;
}) => {
  return (
    <Button
      variant="ghost"
      onClick={onClick}
      className="bg-white shadow-img w-full max-w-[152px] flex flex-col gap-4 p-5 h-fit"
    >
      {icon}
      <div className="text-gray-brand6 text-sm md:text-lg text-center">{children}</div>
    </Button>
  );
};

export default ActionButton;
