'use client';

import PropTypes from 'prop-types';
import { LazyMotion, m, domMax } from 'framer-motion';
import { ReactNode } from 'react';

// ----------------------------------------------------------------------

export function MotionLazy({ children }: { children: ReactNode }) {
  return (
    <LazyMotion strict features={domMax}>
      <m.div style={{ height: '100%' }}> {children} </m.div>
    </LazyMotion>
  );
}

MotionLazy.propTypes = {
  children: PropTypes.node,
};
