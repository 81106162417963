import Img from '@/components/atom/img';
import { User } from 'lucide-react';
import React from 'react';
import { twMerge } from 'tailwind-merge';

type Props = {
  src?: string;
  size?: number;
  className?: string;
  alt?: string;
  hideOnlineStatus?: boolean;
  stroke?: number;
  style?: React.CSSProperties;
  rootClassName?: string;
};

export function Avatar({
  src,
  size = 32,
  className,
  alt = 'image',
  hideOnlineStatus = false,
  stroke = 4,
  style,
  rootClassName,
}: Props) {
  return (
    <div className={twMerge('relative', rootClassName)} style={style}>
      {src ? (
        <div
          className={twMerge(` border-${stroke} border-white shadow`, className)}
          style={{
            width: `${size}px`,
            height: `${size}px`,
            position: 'relative',
            borderRadius: '100%',
            overflow: 'hidden',
          }}
        >
          <Img src={src} className="object-cover" alt={alt} layout="fill" />
        </div>
      ) : (
        <User
          size={size}
          className={twMerge(
            `object-cover border-${stroke} bg-pink-brand text-gray-brand border-white rounded-full shadow`,
            className
          )}
        />
      )}

      {!hideOnlineStatus && (
        <div
          className={twMerge(
            `absolute bottom-0 right-0 w-[${stroke * 3}px] h-[${
              stroke * 3
            }px] bg-green-600 rounded-full border-white shadow`,
            `border-${stroke / 2}`
          )}
        />
      )}
    </div>
  );
}

export default Avatar;
