import { createCommunity } from '@/services/communities.service';
import { CreateCommunityPayloadType } from '@/types';
import { useMutation } from '@tanstack/react-query';

const useCreateCommunityMutation = () => {
  return useMutation({
    mutationKey: ['CreateCommunity'],
    mutationFn: (payload: CreateCommunityPayloadType) => createCommunity(payload),
  });
};

export default useCreateCommunityMutation;
