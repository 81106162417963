import { createEvent } from '@/services/shop.service';
import { CreateEventPayloadType } from '@/types';
import { useMutation } from '@tanstack/react-query';

const useCreateEventMutation = (isReset = false) => {
  return useMutation({
    mutationKey: ['CreateEventMutation', { reset: isReset }],
    mutationFn: (payload: CreateEventPayloadType) => createEvent(payload),
  });
};

export default useCreateEventMutation;
