import React from 'react';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from './atom/dialog';
import { Button } from './atom/button';
import { ChevronLeft } from 'lucide-react';
import Webcam from 'react-webcam';
import base64ToFile from '@/lib/base64-to-file';

type WebcamModalProp = {
  onCaptureImage: (str: string | null | undefined, f?: File) => void;
  type: 'landscape' | 'portrait';
  open: boolean;
  onOpenChange: () => void;
};

const aspectRation = {
  landscape: {
    width: 1920,
    height: 1080,
  },
  portrait: {
    height: 1920,
    width: 1080,
  },
};

const WebcamModal = ({ onCaptureImage, type, open, onOpenChange }: WebcamModalProp) => {
  const webcamRef = React.useRef<Webcam | null>(null);

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef?.current?.getScreenshot();
    const fileName = `capture-${new Date().toLocaleDateString()}`;
    const file = base64ToFile(imageSrc as string, fileName);
    onCaptureImage(imageSrc, file);
    onOpenChange();
  }, [webcamRef, onCaptureImage]);

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="w-screen h-screen z-[1070] md:h-fit p-4 md:p-10 antialiased flex flex-col items-center  md:w-full max-w-[824px] rounded-xl">
        <DialogHeader className="w-full mx-auto text-start">
          <DialogTitle className="relative font-medium text-lg md:text-lg text-purple-brand3 items-center flex gap-2">
            <Button
              variant="ghost"
              size="icon"
              className="rounded-full h-fit w-fit md:hidden"
              onClick={onOpenChange}
            >
              <ChevronLeft size={16} />
            </Button>
            <span className="sr-only">Capture Image</span>
            Capture Image
          </DialogTitle>
        </DialogHeader>
        <div className="flex h-full items-center flex-col  gap-8 w-full mt-6">
          <Webcam
            ref={webcamRef}
            screenshotFormat="image/png"
            screenshotQuality={1}
            videoConstraints={{
              facingMode: 'user',
              ...aspectRation[type],
            }}
          />
        </div>
        <DialogFooter className="justify-end flex-row gap-4 w-full">
          <Button variant="outline" size="sm" onClick={onOpenChange}>
            Cancel
          </Button>
          <Button size="sm" onClick={capture}>
            Capture
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default WebcamModal;
