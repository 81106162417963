'use client';

import React from 'react';
import ValidateEmailForm from './ValidateEmailForm';
import ValidateEmailFailed from './ValidateEmailFailed';
import { Dialog, DialogContent } from '@/components/atom/dialog';
import Progressing from '@/components/atom/Progressing';

const ValidateEmailAddress = ({
  status,
  emailAddress = '',
  openChange,
  onValidateOtp,
  processing,
  description,
  onResend,
}: {
  description?: string;
  processing: boolean;
  status: 'pending' | 'error' | 'idle' | 'success';
  emailAddress: string;
  openChange: (v?: boolean) => void;
  onValidateOtp: (otp: string) => void;
  onResend: () => void;
}) => {
  return (
    <Dialog open={Boolean(emailAddress)} onOpenChange={openChange}>
      <DialogContent className="w-full max-w-[572px]">
        {processing ? (
          <Progressing description={description && 'Validating email address...'} />
        ) : (
          <>
            {status === 'error' ? (
              <ValidateEmailFailed onGoBack={openChange} />
            ) : (
              <ValidateEmailForm
                email={emailAddress}
                onSubmit={onValidateOtp}
                onChangeEmail={() => openChange(false)}
                onResend={onResend}
              />
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ValidateEmailAddress;
