/**
 * Convert a base64 string to a File object
 * @param base64String - The base64 encoded image string
 * @param fileName - The name of the file to create
 * @returns File - The created file object
 */
// export default function base64ToFile(base64String: string, fileName: string): File {
//   const arr = base64String.split(',');
//   const mime = arr[0].match(/:(.*?);/)?.[1] || '';
//   const bstr = atob(arr[1]);
//   let n = bstr.length;
//   const u8arr = new Uint8Array(n);

//   while (n--) {
//     u8arr[n] = bstr.charCodeAt(n);
//   }

//   return new File([u8arr], fileName, { type: mime });
// }

export default function base64ToFile(base64String: string, fileName: string): File {
  const arr = base64String.split(',');
  const mime = arr[0].match(/:(.*?);/)?.[1] || '';
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  // Extract the extension from the MIME type
  const extension = mime.split('/')[1];
  const hasExtension = fileName.includes('.');

  // Append the extension if the fileName does not have one
  const finalFileName = hasExtension ? fileName : `${fileName}.${extension}`;

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], finalFileName, { type: mime });
}
