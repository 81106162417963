import { updateEventDetail } from '@/services/shop.service';
import { UpdateEventDetailFormType } from '@/types';
import { useMutation } from '@tanstack/react-query';

const useUpdateEventDetailMutation = () => {
  return useMutation({
    mutationKey: ['UpdateEventDetail'],
    mutationFn: (payload: UpdateEventDetailFormType) => updateEventDetail(payload),
  });
};

export default useUpdateEventDetailMutation;
