import React from "react";

import { cn } from "@/lib/utils";
import { Info } from "lucide-react";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  errorMessage?: string;
  hideErrorIcon?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, hideErrorIcon, errorMessage, ...props }, ref) => {
    return (
      <div className="relative">
        <input
          type={type}
          className={cn(
            "flex h-9 w-full duration-500 ease-in-out rounded-sm border border-gray-brand2 bg-white px-3 py-1 text-sm transition text-black-brand file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-brand9 focus-visible:outline-none focus-visible:ring-0 focus-visible:border-purple-brand disabled disabled:bg-gray-brand4",
            className,
            errorMessage &&
              "border-opal-brand5 focus-visible:border-opal-brand5"
          )}
          ref={ref}
          {...props}
        />
        {!hideErrorIcon && errorMessage && (
          <Info
            size={16}
            strokeWidth={1.5}
            className="text-opal-brand transition duration-500 ease-linear inset-y-2.5 end-0 me-2.5 absolute"
          />
        )}
        {errorMessage && (
          <p className="text-sm text-opal-brand transition duration-500 ease-in-out">
            {errorMessage}
          </p>
        )}
      </div>
    );
  }
);
Input.displayName = "Input";

export { Input };
