import { create } from 'zustand';

type ShopSuccessType = {
  base64: string;
  name: string;
  id: number;
  slug: string;
};

type ShopStoreType = {
  shopSuccess: ShopSuccessType | null;
  setShopSuccess: (val: ShopSuccessType | null) => void;
};

const useShopStore = create<ShopStoreType>((set) => ({
  shopSuccess: null,
  setShopSuccess: (payload) =>
    set(() => ({
      shopSuccess: payload,
    })),
}));

export default useShopStore;
