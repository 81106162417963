'use client';

import React from 'react';
import ModalNew from '@/components/atom/modal-new';
import { Button } from '@/components/atom/button';
import { X } from 'lucide-react';
import { ChevronLeft } from 'lucide-react';

interface Props {
  isOpen: boolean;
  onDiscardCreation: () => void;
  onContinueCreation: () => void;
}

const ConfirmPostCreationCloseModal = ({
  isOpen,
  onDiscardCreation,
  onContinueCreation,
}: Props) => {
  return (
    <ModalNew
      isOpen={isOpen}
      onClose={onContinueCreation}
      asDrawerOnMobile={true}
      classNames={{
        content:
          'w-screen md:w-[35vw] md:max-w-[600px] h-screen md:h-[40vh] max-h-screen md:max-h-[80vh] px-0 md:px-4 py-1 md:py-3 rounded-none md:rounded-lg',
        footer: 'border-t-0',
        title: 'p-3 md:p-6',
        childrenContainer: `p-3 md:p-6`,
      }}
      title={
        <div className="flex justify-between items-center w-full">
          <div className="flex items-center gap-1">
            <Button
              variant="ghost"
              className="block md:hidden p-0 appearance-none focus:outline-none"
              aria-label="Close"
              onClick={onContinueCreation}
            >
              <ChevronLeft size={16} />
            </Button>

            <h5 className="m-0 p-0 text-lg md:text-2xl font-medium text-purple-brand3">
              Discard this Post?
            </h5>
          </div>

          <Button
            variant="ghost"
            className="hidden md:flex h-[25px] w-[25px] p-0 appearance-none focus:outline-none"
            aria-label="Close"
            onClick={onContinueCreation}
          >
            <X size={20} />
          </Button>
        </div>
      }
      description=""
      hasCloseButton={false}
      footer={
        <div className="flex flex-col md:flex-row justify-center gap-4 items-center p-0 md:w-full">
          <Button
            className="w-full text-purple-brand "
            variant="outline"
            onClick={onDiscardCreation}
          >
            Yes, Discard It
          </Button>
          <Button className="w-full text-white" onClick={onContinueCreation}>
            No, Continue Editing
          </Button>
        </div>
      }
    >
      <div className="w-full h-full bg-white rounded-sm flex flex-col">
        <p className="text-[#49404C] font-normal text-base">
          Are you sure you want to close this modal?
        </p>
        <p className="text-[#49404C] font-normal text-base">
          Your post will be lost and cannot be recovered.
        </p>
      </div>
    </ModalNew>
  );
};

export default ConfirmPostCreationCloseModal;
