import { updateEventTitle } from '@/services/shop.service';
import { UpdateEventTitleFormType } from '@/types';
import { useMutation } from '@tanstack/react-query';

const useUpdateEventTitleMutation = () => {
  return useMutation({
    mutationKey: ['UpdateEventTitle'],
    mutationFn: (payload: UpdateEventTitleFormType) => updateEventTitle(payload),
  });
};

export default useUpdateEventTitleMutation;
